
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faTimes as fasTimes,
        faChevronDown as fasChevronDown,
        faChevronUp as fasChevronUp,
        faChevronLeft as fasChevronLeft,
        faChevronRight as fasChevronRight,
    } from '@fortawesome/pro-solid-svg-icons';

    import C1KeywordMixins from './C1KeywordMixins';

    export default {
        name: 'C1KeywordSearchMobile',

        components: {
            FontAwesomeIcon,
            Checkbox: () => import('@/components/Utils/Checkbox'),
        },

        mixins: [C1KeywordMixins],

        props: {
            animate: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isAnimated: false,
                isAnimated2: false,
                icons: {
                    fasTimes,
                    fasChevronDown,
                    fasChevronUp,
                    fasChevronLeft,
                    fasChevronRight,
                },
            };
        },

        mounted() {
            this.isAnimated = true;
            setTimeout(() => {
                this.isAnimated2 = true;
            }, 50);
        },
    };
