
    import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
    import {
        faChevronLeft as fasChevronLeft,
        faChevronRight as fasChevronRight,
        faSlidersSimple as fasSlidersSimple,
        faTimes as fasTimes,
    } from '@fortawesome/pro-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    import SaveSearch from '@/components/SaveSearch/SaveSearch';
    import C1TypeaheadSingleSelect from '@/C1Components/C1TypeaheadSingleSelect.vue';
    import C1KeywordSearchMobile from '@/C1Components/C1KeywordSearchOptimised/C1KeywordSearchMobile.vue';
    import SearchbarMixin from '@/components/Search/Searchbars/SearchbarMixin';

    export default {
        name: 'SearchbarStacked',

        components: {
            SearchbarFilters: () => process.client ? import(/* webpackChunkName: "SearchbarFilters" */'@/components/Search/SearchbarFilters') : {},
            C1KeywordSearchMobile,
            C1TypeaheadSingleSelect,
            SaveSearch,
            FontAwesomeIcon,
        },

        mixins: [SearchbarMixin],

        props: {
            exitUrl: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                showLocationIcon: false,
                isLocationSearchActive: false,
                isFullscreen: false,
                activateAnimate: false,
                isAnimated: false,
                isAnimated2: false,
                icons: {
                    farSearch,
                    fasChevronLeft,
                    fasChevronRight,
                    fasSlidersSimple,
                    fasTimes,
                },
            };
        },

        watch: {
            '$_search.activateSearchAnimate'(isActivated) {
                if (isActivated) {
                    setTimeout(() => {
                        this.isAnimated = true;
                    }, 100);

                    setTimeout(() => {
                        this.isAnimated2 = true;
                    }, 150);
                } else {
                    this.isAnimated = false;
                    this.isAnimated2 = false;
                }
            },
        },

        methods: {
            openSearch() {
                this.$_SET({
                    key: 'isSearchSummary',
                    value: false,
                });

                this.$_SET({
                    key: 'activateSearchAnimate',
                    value: true,
                });
            },

            closeLocationSearch() {
                this.isLocationSearchActive = false;
                window.location.hash = '';

                this.$_SET({
                    key: 'activateSearchAnimate',
                    value: false,
                });

                this.$_SET({
                    key: 'isSearchFullscreenMode',
                    value: false,
                });
            },

            openLocationSearch() {
                this.isLocationSearchActive = true;
            },

            onLocationChanged(selectedLocation) {
                if (selectedLocation.id) {
                    this.closeLocationSearch();
                    this.searchClick();
                }
            },

            handleSubmitSearch(e) {
                this.submitSearch(e, () => {
                    this.$_SET({
                        key: 'isSearchSummary',
                        value: true,
                    });
                });
            },

            clearSearchLocation() {
                this.searchFilterLocation = {};
                this.handleLocationEnter();
            },

            onLocationFullscreenOpen(fullscreen) {
                this.isFullscreen = fullscreen;
                this.$_SET({
                    key: 'isSearchFullscreenMode',
                    value: this.isFullscreen,
                });
                if (!this.isFullscreen) {
                    this.closeLocationSearch();
                }
            },
        },
    };
